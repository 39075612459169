import { useStaticQuery, graphql } from "gatsby"

const useWebAppDevelpomentProjectQuery = () => {
  const webAppDevelpomentProjectQuery = useStaticQuery(graphql`
    query webAppDevelpomentProjectQuery {
      allFile(
        filter: {
          extension: { eq: "png" }
          name: { in: ["immobilenscout24-mockup"] }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return webAppDevelpomentProjectQuery
}

export default useWebAppDevelpomentProjectQuery
