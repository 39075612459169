import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import QuickContact from "../components/Sections/QuickContact"
import Button from "../components/UI/Button"
import { OrangeSpan, GrayBorder, Section, DoubleGrid } from "../globalStyle"
import useWebAppDevelopmentQuery from "../helpers/queries/webAppDevelopmentQuery"
import useWebAppDevelpomentProjectQuery from "../helpers/queries/webAppDevelopmentProjectQuery"
import Companies from "../components/Sections/Companies"
import TrustedUs from "../components/Sections/TrustedUs"
import PageTitle from "../components/Elements/PageTitle"
import webAppDevelopmentChainData from "./../data/components/webAppDevelopmentChain"
import ChainDevelopment from "../components/Sections/ChainDevelopment"
import ClutchTitle from "../components/Sections/ClutchTitle"
import Project from "../components/UI/Project"
import webAppDevelopmentProjectData from "../data/components/webAppDevelopmentProject"
import { Container } from "../components/Sections/CaseStudies/style"

const WebAppDevelopment = () => {
  const webAppDevelopmentData = useWebAppDevelopmentQuery()
  const webAppDevelopmentProjectImageData = useWebAppDevelpomentProjectQuery()
  return (
    <Layout>
      <Head
        description="Your digital product build by our services Every business has its own language. Our software development team converts business processes into machine languages, using Domain Driven Design."
        pageTitle="Web app development"
        keywords="software, oakfusion"
      />
      <div style={{ marginBottom: "150px" }}></div>
      <Section>
        <PageTitle title="Web App Development" />
      </Section>
      <GrayBorder />
      <ClutchTitle
        title="Your digital product"
        coloredTitle="build by our services"
        text="Every business has its own language. Our software development team converts business processes into machine languages, using Domain Driven Design.
We have developed numerous web applications and platforms using newest programming frameworks such as: Java Spring, Angular.js, React,js and cloud based computing services including AWS and Digital Ocean.
Over 25+ skilled professional developers are working in our projects for various industries, providing tested and high quality code."
        Button={() => <Button slug="/contact">Order free consultation</Button>}
      />
      <Section>
        <Container>
          <DoubleGrid>
            {webAppDevelopmentProjectData.map(p => (
              <Project
                key={p.id}
                isInService={true}
                data={p}
                image={
                  webAppDevelopmentProjectImageData.allFile.edges[p.id - 1].node
                    .childImageSharp.gatsbyImageData
                }
              />
            ))}
          </DoubleGrid>
        </Container>
      </Section>

      <ChainDevelopment chainElements={webAppDevelopmentChainData} />
      <TrustedUs
        text="Oakfusion’s professional software development skills, their deep understanding for business driven requirements and their proactive working style have been an important success factor for the launch of our new product within given time frame and budget."
        name="Patrick Gerstenecker, Senior Product Manager"
        who="Immobilienscout24.de"
      />
      <Companies companies={webAppDevelopmentData} />
      <QuickContact
        title={
          <>
            Got other <OrangeSpan>ideas?</OrangeSpan>
          </>
        }
        text={"Make it happen"}
        button={<Button slug="/">Contact</Button>}
      />
    </Layout>
  )
}

export default WebAppDevelopment
