import commentText from "@iconify-icons/mdi-light/comment-text"
import puzzleIcon from "@iconify-icons/et/puzzle"
import clipboardCode20Regular from "@iconify-icons/fluent/clipboard-code-20-regular"
import gearsIcon from "@iconify-icons/et/gears"

const webAppDevelopmentChainData = [
  {
    id: 1,
    title: "Free project estimation",
    image: commentText,
    text:
      "Get in touch with us and describe your project well, so we can estimate it accurately.",
  },
  {
    id: 2,
    title: "Planning and consulting",
    image: puzzleIcon,
    text:
      "Let’s sit down together and plan every piece of the puzzle. We will talk in depth about technologies, functionalities, design, milestones and what’s next.",
  },
  {
    id: 3,
    title: "Implementation & testing",
    image: clipboardCode20Regular,
    text:
      "We code, test and deliver until every single task in the pipeline is done, functionalities are implemented and every function is being automatically tested.",
  },
  {
    id: 4,
    title: "Deployment",
    image: gearsIcon,
    text:
      "We know that good code is the one that’s easy to maintain and develop. We keep watch over our work, even after it’s finished.",
  },
]

export default webAppDevelopmentChainData
