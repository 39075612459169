import immobilenScout24 from "../../data/images/products/immobilenscout24-mockup.png"

const webAppDevelopmentProjectData = [
  {
    id: 1,
    title: "Immobilen Scout24",
    image: immobilenScout24,
    slug: "/case-studies/immobilien-scout24/",
    description_1:
      "ImmobilienScout24 (IS24) is part of the business that is responsible for property services. It is the biggest estate portal in Germany that offers apartments and houses both for rent and for sale. With more than 10 million visitors per month, ImmobilienScout24 assists by providing a variety of services and innovative products to their clients and helps users find their dream home.",
    description_2:
      "We had started and built this application from scratch, successfully implementing all functions.",
    backgroundColor: "#21375d",
  },
]

export default webAppDevelopmentProjectData
